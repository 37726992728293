//this file contains temp data for simulation of the http request
const grahDeals:any = {
  legacy:{
    graphA: [384, 385, 541, 867, 382, 343, 321],
    graphB: [224, 185, 441, 167, 582, 143, 221],
    graphC: [184, 185, 341, 567, 282, 143, 621],
    graphD: [19.3,38.7,50.6,53.3,54.7,53.8,54.6,56.7,56.9,56.1,56.5,60.3,58.7,61.4,61.1,58.5,54.7,52.0,51.0,47.4,48.5,48.9,53.5,50.2,
      46.2,48.6,51.7,51.3,50.2,54.6,52.4,53.0,57.0,52.9,48.7,52.6,53.5,58.5,55.1,58.0,61.3,57.7,60.2,61.0,57.7,56.8,
      58.9,62.4,58.7,58.4,56.7,52.7,52.3,50.5,55.4,50.4,52.4,48.7,47.4,43.3,38.9,34.7,31.0,32.6,36.8,35.8,32.7,33.2,30.8,
      28.6,28.4,27.7,27.7,25.9,24.3,21.9,22.0,23.5,27.3,30.2,27.2,29.9,25.1,23.0,23.7,23.4,27.9,23.2,23.9,19.2,15.1,15.0,
      11.0,9.20,7.47,11.6,15.7,13.9,12.5,13.5,15.0,13.9,13.2,18.1,20.6,21.0,25.3,25.3,20.9,18.7,15.3,14.5,17.9,15.9,
      16.3,14.1,12.1,14.8,17.2,17.7,14.0,18.6,18.4,22.6,25.0,28.1,28.0,24.1,24.2,28.2,26.2,29.3,26.0,23.9,28.8,25.1,
      21.7,23.0,20.7,29.7,30.2,32.5,31.4,33.6,30.0,34.2,36.9,35.5,34.7,36.9],
  },
  acero:{
    graphA: [334, 125, 451, 657, 112, 213, 431],
    graphB: [184, 185, 341, 567, 282, 143, 621],
    graphC: [224, 185, 441, 167, 582, 143, 221],
    graphD: [20.3,38.7,50.6,53.3,14.7,53.8,14.6,56.7,16.9,56.1,56.5,60.3,28.7,61.4,11.1,58.5,14.7,52.0,11.0,47.4,48.5,48.9,13.5,50.2,
      46.2,48.6,11.7,51.3,50.2,24.6,52.4,53.0,17.0,52.9,48.7,52.6,13.5,58.5,55.1,58.0,21.3,57.7,60.2,11.0,57.7,56.8,
      28.9,62.4,58.7,38.4,56.7,12.7,52.3,10.5,55.4,50.4,52.4,28.7,47.4,43.3,18.9,34.7,11.0,32.6,36.8,35.8,22.7,33.2,30.8,
      28.6,28.4,27.7,67.7,25.9,24.3,21.9,62.0,23.5,27.3,30.2,27.2,29.9,25.1,63.0,23.7,63.4,27.9,23.2,23.9,69.2,15.1,15.0,
      11.0,9.20,7.47,11.6,15.7,13.9,12.5,13.5,15.0,13.9,13.2,18.1,20.6,21.0,25.3,25.3,20.9,18.7,15.3,14.5,17.9,15.9,
      16.3,64.1,12.1,54.8,57.2,17.7,14.0,18.6,18.4,42.6,25.0,28.1,28.0,24.1,24.2,68.2,26.2,29.3,26.0,53.9,28.8,25.1,
      21.7,63.0,20.7,29.7,60.2,32.5,31.4,63.6,30.0,34.2,36.9,65.5,34.7,16.9],
  }
};

const resourceTable = [
  [103,'03/15/22','Gustavo','Informations of order','Quotes','Notes','show detail'],
  [103,'03/15/22','Gustavo','Informations of order','Quotes','Notes',''],
  [112,'03/12/22','Francisco','Informations of order','Quotes','Notes',''],
  [134,'03/13/22','Javier','Informations of order','Collection','Notes',''],
  [167,'03/14/22','Daniel','Informations of order','Collection','Notes',''],
  [189,'03/14/22','Jesus','Informations of order','Orders','Notes',''],
  [124,'03/12/22','Yesica','Informations of order','Orders','Notes',''],
  [112,'03/13/22','Karen','Informations of order','Orders','Notes',''],
  [163,'03/12/22','Susana','Informations of order','Paid','Notes',''],
  [178,'03/13/22','Yenni','Informations of order','On Hold','Notes',''],
  [197,'03/14/22','Alejandra','Informations of order','Paid','Notes',''],
  [183,'03/15/22','Sofia','Informations of order','On Hold','Notes',''],
  [100,'03/15/22','Roberto','Informations of order','Estimate','Notes','']
]
export {grahDeals, resourceTable};
