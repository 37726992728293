import { MenuItem } from './menu.model';


const items:any = [
  {
    own:'gama',
    label: 'Orders',
    icon: 'clipboard',
    link: '/dashboard/orders/order/format'
  },
  {
    own:'beta',
    label: 'Orders',
    icon: 'clipboard',
    link: '/dashboard/estimates/estimate'
  },
  {
    label: 'Estimates',
    icon: 'clipboard',
    subItems: [
      {
        label: 'Estimates',
        link: '/dashboard/estimates/estimate'
      },
      {
        label: 'Customers',
        link: '/dashboard/estimates/customer'
      },
      {
        label: 'Special Princing',
        link: '/dashboard/estimates/special-pricing'
      },
    ]
  },
  {
    own:'beta',
    label: 'Products',
    icon: 'clipboard',
    link: '/dashboard/products/product'
  },
  {
    own:'gama',
    label: 'Repairs',
    icon: 'layout',
    link: '/dashboard/repairs/repair'
  },
  {
    label: 'Collections',
    icon: 'dollar-sign',
    link: '/dashboard/collections/collection'
  },
  {
    own:'gama',
    label: 'Dealers',
    icon: 'truck',
    link: '/dashboard/dealers/dealer'
  },
  {
    own:'gama',
    label: 'Dealer Maps',
    icon: 'map',
    link: '/dashboard/schedulers/dealer'
  },
  {
    own:'beta',
    label: 'Customers',
    icon: 'users',
    link: '/dashboard/estimates/customer'
  },
  {
    own:'beta',
    label: 'Special Princing',
    icon: 'dollar-sign',
    link: '/dashboard/estimates/special-pricing'
  },
];
const selectItemsMenu = (own=null, tipo=3, user=null) =>{
  let tmp = [];

  if(tipo == 1){
    tmp.push(
      {
        own:'both',
        label: 'Settings',
        icon: 'settings',
        subItems: [
          {
            label: 'Users',
            link: '/dashboard/users/user'
          },
        ]
      },
    );
  }

  tmp.push(
    {
      own:'both',
      label: 'Dashboard',
      icon: 'home',
      link: 'dashboard'
    },
  );

  items.forEach(element => {
    if(own !=null){
      if(element.own === own){
        if(element.own === 'beta' && element.label === 'Orders' && user ==='aca'){
          element.link = '/dashboard/estimates/caestimate'
        }
        tmp.push(element);
      }
    }else{
      tmp.push(element);
    }
  });
  return tmp;
}

export const LoadMenu = (user, profile: number) =>{
  let tmp:MenuItem[] = [];



  switch (user) {
    case 'atx':
      tmp = selectItemsMenu('beta', profile);
      break;
    case 'aca':
      tmp = selectItemsMenu('beta', profile);
      break;
    case 'ain':
      tmp = selectItemsMenu('beta', profile);
      break;
    case 'lgy':
      tmp = selectItemsMenu('gama', profile);
      break;
    case 'uss':
      tmp = selectItemsMenu('gama', profile);
      break;
    case 'aab':
      tmp = selectItemsMenu('gama', profile);
      break;
    case 'star':
        tmp = selectItemsMenu('gama', profile);
        break;
    case 'fc':
        tmp = selectItemsMenu('gama', profile);
        break;
    default:
      tmp = selectItemsMenu();
      break;
  }
  tmp.push(
    {
      label: 'Schedulers',
      icon: 'map',
      link: '/dashboard/schedulers/order'
    },
  );
  return tmp;
}
