import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  private currentCompany:string = 'Legacy';
  private dataCompany:any[]=[
    {name:'Legacy', dataChA:[36, 77, 52, 90, 74, 35, 55, 23, 47, 10, 63], daleSale:'35,084', dalePercent:-2.8},
    {name:'Acero', dataChA:[65, 10, 45, 90, 89, 21, 90, 42, 89, 100, 45], daleSale:'40,067', dalePercent:5.6}
  ];
  constructor() { }

  public getCurrentCompany(){
    return this.currentCompany;
  }

  public setCurrentCompany(name:string){
    this.currentCompany = name;
  }

  public getDataCompany(company){
    let dataC = this.dataCompany.find((item)=>{
      if(item.name === company){
        return item;
      }
    })
    return dataC;
  }

  public getImgCompany(){
    let nameCompany:string = localStorage.getItem('nameCompany');
    switch (nameCompany) {
      case 'Legacy':
        return {
            url:'./assets/images/legacy.svg',
            print: './assets/images/legacy.png',
            name:'All American', address:'3007 NW Stallings Dr Nacogdoches',
            state:'Texas',
            zip:'75864',
            phone:'1(122) 319-0301'
          };
        break;
      case 'Acero':
        return {
            url:'./assets/images/acero.svg',
            print: './assets/images/acero.png',
            name:'Acero',
            address:'3007 NW Stallings Dr Nacogdoches',
            state:'Arizona',
            zip:'35464',
            phone:'1(544) 319-2453'
          };
          break;
      case 'Northedge':
        return {
            url:'./assets/images/northedge.svg',
            print: './assets/images/northedge.png',
            name:'Nortedge',
            address:'3007 NW Stallings Dr Nacogdoches',
            state:'New York',
            zip:'74564',
            phone:'1(233) 319-9715'
          };
        break;
      case 'Steel':
        return {
            url:'./assets/images/steel.svg',
            print: './assets/images/steel.png',
            name:'Steel',
            address:'3007 NW Stallings Dr Nacogdoches',
            state:'Dallas',
            zip:'75664',
            phone:'1(744) 319-2617'
          };
        break;
    }
  }
}
