import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Loader} from '@googlemaps/js-api-loader';

// Test import data
import {grahDeals, resourceTable} from './resource-temp';


@Injectable({
  providedIn: 'root'
})
export class ApiService {
  // private localUrl = 'http://localhost:3000/api';
  // private localUrl = 'https://andromeda.allamericanmbs.com/api';

  // private localUrl = 'https://alphat.allamericanmbs.com/api';
  private localUrl = 'https://andromeda.allamericanmbs.com/api';
  // private localUrl = 'http://192.168.1.213:3000/api';
  // private localUrl = 'http://localhost:3000/api';

  // private urlGama = 'https://gamat.allamericanmbs.com/api';
  private urlGama = 'https://centaurus.allamericanmbs.com/api';
  // private urlGama = 'http://192.168.1.213:3060/api';
  // private urlGama = 'http://localhost:3060/api';

  // private urlBeta = 'https://betat.allamericanmbs.com/api';
  private urlBeta = 'https://volans.allamericanmbs.com/api';
  // private urlBeta= 'http://192.168.1.213:3050/api';
  // private urlBeta = 'http://localhost:3050/api';



  private apiMaps = 'https://maps.googleapis.com/maps/api/geocode/json';
  private keyMaps = new Loader({
    apiKey: 'AIzaSyAKXhW-9e4a_0Qxdrbt-ddt84CjbOMo15Q',
    version: 'weekly',
    libraries: ['places']
  });

  private tempData: any = grahDeals;
  // Orders Data
  private resourceTable: any = resourceTable;

  constructor(private http: HttpClient) {
  }

  // LOGIN
  login(user: any): Observable<any> {
    return this.http.post(this.localUrl + '/login', user);
  }

  login2() {
    return this.http.get(this.localUrl + '/suser');
  }

  ////////////////////  GEOCODING ///////////////////////////////
  getGeocoding(address: any): Observable<any> {
    return this.http.get(this.apiMaps + '?address=' + '&key' + this.keyMaps.apiKey)
  }

  // CREATE COMPANY
  createComapny(company: any): Observable<any> {
    /*let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers.append('Content-Type', 'multipart/form-data');
    return this.http.post(this.localUrl+"/company", company, {"headers":headers})*/
    return this.http.post(this.localUrl + '/company', company)
  }

  // CREATE ACOUNT SUPER USER
  createAcount(user: any, db: any): Observable<any> {
    return this.http.post(this.localUrl + '/user/' + db, user);
  }

  /////////////////////  USERS ///////////////////////////////
  // Get Profiles
  getProfiles(): Observable<any> {
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.get(this.localUrl + '/profile/' + company_database);
  }

  // Get Users
  getUsers(): Observable<any> {
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.get(this.localUrl + '/user/' + company_database);
  }


  // Create User
  createUser(user: any): Observable<any> {
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.post(this.localUrl + '/user/' + company_database, user);
  }

  // Get Users by ID
  getUser(id:any): Observable<any> {
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.get(this.localUrl + '/user/' + company_database + '/'+ id);
  }

  // UPDATE User
  updateUser(user: any): Observable<any> {
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    // return this.http.get(this.urlGama+"/dealer/"+company_database);
    return this.http.put(this.localUrl + '/user/' + company_database + '/' + user.id, user);
  }

  updatePasswordUser(id: any, password: any): Observable<any>{
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.put(this.localUrl + '/user/' + company_database + '/' + id + '/password', password);
  }

  getColors(): Observable<any> {
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.get(this.urlBeta + '/colors/' + company_database);
  }
  getColorsAcero(): Observable<any> {
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.get(this.urlBeta + '/secondary_colors/' + company_database);
  }

  //////////////////// GET STATES //////////////////////
  getStates(): Observable<any> {
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.get(this.urlGama + '/building/states/' + company_database);
  }

  ////////////////////  DEALERS ///////////////////////////////
  // CREATE DEALER
  saveDealer(dealer: any): Observable<any> {
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    // return this.http.post(this.urlGama+"/dealer/"+company_database, dealer);
    return this.http.post(this.urlGama + '/dealer/' + company_database, dealer);
  }

  // GET DEALER
  getDealer(ID: any): Observable<any> {
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.get(this.urlGama + '/dealer/' + company_database + '/' + ID);
  }

  // GET DEALERS
  getDealers(): Observable<any> {
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    // return this.http.get(this.urlGama+"/dealer/"+company_database);
    return this.http.get(this.urlGama + '/dealer/' + company_database);
  }

  // UPDATE DEALER
  updateDealer(dealer: any): Observable<any> {
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    // return this.http.get(this.urlGama+"/dealer/"+company_database);
    return this.http.put(this.urlGama + '/dealer/' + company_database + '/' + dealer.ID, dealer);
  }


  //// DASHBOARD SERVICES

  getDataDashboard(param) {
    let response: any = {};
    switch (param) {
      case 'legacy':
        response = {
          name: 'Legacy',
          chart: this.tempData.legacy,
          total: 45502.23,
          deals: 335,
          orders: 145,
          sale: 3245.50
        };
        break;
      case 'acero':
        response = {name: 'Acero', chart: this.tempData.acero, total: 59316.35, deals: 125, orders: 345, sale: 2245.50};
        break;
      case 'northedge':

        break;
      case 'steel':

        break;
      default:
        response = {
          name: 'Legacy',
          chart: this.tempData.legacy,
          total: 45502.23,
          deals: 335,
          orders: 145,
          sale: 3245.50
        };
        break;
    }
    return response;
  }

  getDashboardData(): Observable<any> {
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.get(this.urlGama + '/dashboard/info/' + company_database);
  }


  getLastTenOrders(): Observable<any> {
    // const company_database = "001096b19fe9d9353cd8c25785c135444fa5f48a1ce024e45f84bac41498cdd9a05d35c8bec5f5bfdce2f6";
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.get(this.urlGama + '/dashboard/last_orders/' + company_database);
  }

  getTopDealers(): Observable<any> {
    // const company_database = "001096b19fe9d9353cd8c25785c135444fa5f48a1ce024e45f84bac41498cdd9a05d35c8bec5f5bfdce2f6";
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.get(this.urlGama + '/dashboard/dealers_orders/' + company_database);
  }

  totalDealers(): Observable<any>{
    // const company_database = "001096b19fe9d9353cd8c25785c135444fa5f48a1ce024e45f84bac41498cdd9a05d35c8bec5f5bfdce2f6";
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.get(this.urlGama + '/dashboard/dealer_numbers/' + company_database);
  }

  getTotalMonth(): Observable<any> {
    // const company_database = "001096b19fe9d9353cd8c25785c135444fa5f48a1ce024e45f84bac41498cdd9a05d35c8bec5f5bfdce2f6";
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.get(this.urlGama + '/dashboard/orders_chart/'+company_database);
  }

  //New Dashboard Services
  getNewDataDashboard() {
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.get(this.urlBeta + '/dashboard/' + company_database);
  }

  // tslint:disable-next-line:jsdoc-format
  /**Order endpoints **************************/

  getDataOrders() {
    const orders: any = {data: this.resourceTable};
    return orders;
  }
  getDataOrdersDelivery(): Observable<any> {
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.get(this.urlGama + '/building_orders_info/' + company_database);
  }

  saveOrder(order: any): Observable<any> {
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.post(this.urlGama + '/building_orders/' + company_database, order);
  }

  getAllOrders(): Observable<any> {
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.get(this.urlGama + '/building_orders/' + company_database);
  }
  getTotalorder(): Observable<any>{
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.get(this.urlGama+'/building_orders/petition/inv/'+company_database);
  }
  getDetailOrder(id): Observable<any>{
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.get(this.urlGama + '/building_orders/' + company_database + '/' + id);
  }
  getMapOrders(): Observable<any>{
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.get(this.urlGama + '/building_orders/petition/map/' + company_database);
  }
  getOrderBuilding(id): Observable<any>{
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.get(this.urlGama + '/building_orders/' + company_database + '/' + id);
  }
  editOrderBuildingStatus(id, dataOrderStatus): Observable<any>{
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.post(this.urlGama + '/building_orders/status/' + company_database + '/' + id, dataOrderStatus);
  }

  updateOrder(id, order): Observable<any>{
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.put(this.urlGama + '/building_orders/' + company_database + '/' + id,order);
  }

  getTax(zipcode): Observable<any>{
    return this.http.get(this.urlGama + '/tax/' + zipcode);
  }

  createNoteBuildings(note): Observable<any>{
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.post(this.urlGama + '/order_note_upload/' + company_database , note);
  }
  getNotesBuildings(order): Observable<any>{
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.get(this.urlGama + '/order/note/' + company_database+'/'+order);
  }
  deleteNoteBuildings(note): Observable<any>{
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.delete(this.urlGama + '/order/note/' + company_database+'/'+note);
  }

  updateStatusInstalled(order, status): Observable<any>{
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.get(this.urlGama + '/building_orders_status/' + company_database+'/'+order,status);
  }
  getOrdersOnQuote(status): Observable<any>{
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.get(this.urlGama + '/building_orders/status/' + company_database+'/'+status);
  }

  getAllOrdersStatus(status): Observable<any> {
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.get(this.urlGama + '/building_orders/' + company_database + '/status/' + status);
  }

  // Request Customers Clients for Beta

  saveCustomerClient(client): Observable<any>{
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.post(this.urlBeta + '/client/' + company_database,client);
  }
  updateCustomerClient(client): Observable<any>{
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.put(this.urlBeta + '/client/' + company_database+'/'+client.ID,client);
  }
  deleteClient(client){
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.delete(this.urlBeta + '/client/' + company_database+'/'+client);
  }
  getBetaStates(): Observable<any>{
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.get(this.urlBeta + '/states/' + company_database);
  }
  getBetaClients(): Observable<any>{
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.get(this.urlBeta + '/client/' + company_database);
  }
  getBetaFindClient(id): Observable<any>{
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.get(this.urlBeta + '/client/' + company_database+'/'+id);
  }
  getCustomFiles(id): Observable<any>{
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.get(this.urlBeta + '/client/' + company_database + "/" + id + "/files");
  }
  uploadCustomFiles(files, id): Observable<any>{
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.post(this.urlBeta + '/client/' + company_database + "/" + id + "/files", files);
  }

  // Products services
  saveProduct(product): Observable<any>{
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.post(this.urlBeta + '/products/' + company_database,product);
  }
  getProducts(): Observable<any>{
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.get(this.urlBeta + '/products/' + company_database);
  }
  findProduct(id): Observable<any>{
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.get(this.urlBeta + '/products/' + company_database+'/'+id);
  }
  updateProduct(product): Observable<any>{
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.put(this.urlBeta + '/products/' + company_database+'/'+product.ID,product);
  }
  deleteProduct(id){
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.delete(this.urlBeta + '/products/' + company_database+'/'+id);
  }
  // Additional Services
  saveCatAdditional(additional){
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.post(this.urlBeta + '/additional/' + company_database,additional);
  }
  getCatAdditional(additional): Observable<any>{
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.get(this.urlBeta + '/additional/' + company_database+'/'+additional);
  }
  deleteAditional(id){
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.delete(this.urlBeta + '/additional/' + company_database+'/'+id);
  }
  editAdditional(additional){
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.put(this.urlBeta + '/additional/' + company_database+'/'+additional.ID,additional);
  }

  // Catalog Length Services
  saveCatLength(item): Observable<any>{
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.post(this.urlBeta + '/catalog/' + company_database,item);
  }
  getCatLength(product): Observable<any>{
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.get(this.urlBeta + '/catalog/' + company_database+'/'+product);
  }
  deleteCatLength(item): Observable<any>{
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.delete(this.urlBeta + '/catalog/' + company_database+'/'+item,);
  }

  // Categories Services
  getCategories(): Observable<any>{
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.get(this.urlBeta + '/categories/' + company_database);
  }

  getCategoryProduct(): Observable<any>{
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.get(this.urlBeta + '/category/product/' + company_database);
  }

  // Special Pricing
  getSpecialPricing(): Observable<any>{
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.get(this.urlBeta + '/special_price/' + company_database);
  }
  saveSpecialPricing(special): Observable<any>{
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.post(this.urlBeta + '/special_price/' + company_database,special);
  }
  updateSpecialPricing(special): Observable<any>{
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.put(this.urlBeta + '/special_price/' + company_database+'/'+special.id,special);
  }

  // Estimates
  getCustomerEsimate(customer): Observable<any>{
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.get(this.urlBeta + '/order/p_client/' + company_database+'/'+customer);
  }
  getTypePayment(): Observable<any>{
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.get(this.urlBeta + '/payment/' + company_database);
  }
  getTermsPayment(): Observable<any> {
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.get(this.urlBeta + '/order/payment_terms/' + company_database);
  }
  getLastEstimate(): Observable<any>{
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.get(this.urlBeta + '/order/order_number/' + company_database);
  }
  saveEstimate(estimate): Observable<any>{
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.post(this.urlBeta + '/orders/' + company_database,estimate);
  }
  getEstimates(): Observable<any>{
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.get(this.urlBeta + '/orders/' + company_database);
  }
  getStatusEstimate(): Observable<any>{
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.get(this.urlBeta + '/status/' + company_database);
  }
  updateStatusEstimate(order, user): Observable<any>{
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.get(this.urlBeta + '/order/status_detail/' + company_database+'/'+order+'/'+user);
  }
  createNoteEstimate(note): Observable<any>{
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.post(this.urlBeta + '/order/notes/' + company_database,note);
  }
  getNotesEstimate(order): Observable<any>{
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.get(this.urlBeta + '/order/notes/' + company_database+'/'+order);
  }
  deleteNoteEstimate(note): Observable<any>{
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.delete(this.urlBeta + '/order/notes/' + company_database+'/'+note);
  }

  updateEstimate(estimate, id): Observable<any>{
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.put(this.urlBeta + '/orders/' + company_database+'/'+id,estimate);
  }

  updateDateEstimate(date, id): Observable<any>{
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.put(this.urlBeta + '/order_date/' + company_database+'/'+id,date);
  }

  getEstimate(id): Observable<any>{
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.get(this.urlBeta + '/orders/' + company_database+'/'+id);
  }

  sendEmailInvoice(order): Observable<any>{
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.get(this.urlBeta + '/order/email/' + company_database+'/'+order);
  }

  getDataCSV(): Observable<any>{
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.get(this.urlBeta + '/order_export/' + company_database);
  }

  getMapOrdersEstimate(): Observable<any>{
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.get(this.urlBeta + '/order_schedule/' + company_database);
  }

  getCatalogsAtx(): Observable<any>{
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.get(this.urlBeta + '/order_info_create/' + company_database);
  }

  // Customer Price
  saveCustomerPrice(price): Observable<any>{
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.post(this.urlBeta + '/special_price_temp/' + company_database,price);
  }
  getCustomerPrices(customer): Observable<any>{
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.get(this.urlBeta + '/special_price_temp/' + company_database+'/'+customer);
  }
  deleteCustomerPrice(customer): Observable<any>{
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.delete(this.urlBeta + '/special_price_temp/' + company_database+'/'+customer);
  }

  // Employee EndPoints
  getEmployee(): Observable<any>{
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.get(this.urlBeta + '/client_employee/' + company_database);
  }
  saveEmployee(data): Observable<any>{
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.post(this.urlBeta + '/client_employee/' + company_database,data);
  }
  deleteEmployee(id): Observable<any>{
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.delete(this.urlBeta + '/client_employee/' + company_database+'/'+id);
  }


  // Repairs
  saveRepair(repair): Observable<any>{
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.post(this.urlGama + '/repair/' + company_database ,repair);
  }
  editRepair(repair, id): Observable<any>{
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.put(this.urlGama + '/repair/' + company_database+'/'+id ,repair);
  }
  deleteRepair(repair): Observable<any>{
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.delete(this.urlGama + '/repair/' + company_database+'/'+repair);
  }
  getRepairs(): Observable<any>{
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.get(this.urlGama + '/repair/' + company_database );
  }
  findRepair(repair): Observable<any>{
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.get(this.urlGama + '/repair/' + company_database+ '/'+repair);
  }

  //Additionals Addresses
  saveAdditionalAddress(data): Observable<any>{
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.post(this.urlBeta + '/client_address/' + company_database,data);
  }
  getAdditionalAddress(): Observable<any>{
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.get(this.urlBeta + '/client_address/' + company_database);
  }
  deletAdditionalAddress(id): Observable<any>{
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.delete(this.urlBeta + '/client_address/' + company_database+'/'+id);
  }
  getOrderAdditionalAddress(id): Observable<any>{
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.get(this.urlBeta + '/order/client_address/' + company_database+'/'+id);
  }

  //Standard Funtions
  getStandarCatalog(product): Observable<any>{
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.get(this.urlBeta + '/catalog/' + company_database+'/'+product);
  }
  saveStandarCatalog(data): Observable<any>{
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.post(this.urlBeta + '/catalog/' + company_database,data);
  }
  deleteStandarCatalog(id): Observable<any>{
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.delete(this.urlBeta + '/catalog/' + company_database+'/'+id);
  }

  //Additional Products
  getAdditionalProducts(product): Observable<any>{
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.get(this.urlBeta + '/order/p_add/' + company_database+'/'+product);
  }
  saveNotes(data, order): Observable<any>{
    const company_database = JSON.parse(localStorage.getItem('usuario')).company_database;
    return this.http.post(this.urlGama + '/order/note/' + company_database+'/'+order,data);
  }

  currencyFormatter({ currency, value}) {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      minimumFractionDigits: 2,
      currency
    })
    return formatter.format(value)
  }

  //General Function
  formatForDate(date:Date){
    const tempDate = new Date(date);
    return (tempDate.getDate() >= 10 ? tempDate.getDate() : '0' + tempDate.getDate()) + "-" + ((tempDate.getMonth() >= 10) ? tempDate.getMonth()+ 1 : ('0' + (tempDate.getMonth() + 1))) + "-" + tempDate.getFullYear().toString().substring(2,4);
  }
}
