import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BaseComponent } from './views/layout/base/base.component';
import { AuthGuard } from './core/guard/auth.guard';
import { ErrorPageComponent } from './views/pages/error-page/error-page.component';



const routes: Routes = [
  {
    path:'',
    loadChildren: () => import('./views/pages/auth/auth.module').then(m => m.AuthModule) ,
  },
  {
    path: 'dashboard',
    component: BaseComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'users',
        loadChildren: () => import('./views/pages/users/users.module').then(m => m.UsersModule)
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./views/pages/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'orders',
        loadChildren: () => import('./views/pages/orders/orders.module').then(m => m.OrdersModule)
      },
      {
        path: 'repairs',
        loadChildren: () => import('./views/pages/repairs/repairs.module').then(m => m.RepairsModule)
      },
      {
        path: 'products',
        loadChildren: () => import('./views/pages/products/products.module').then(m => m.ProductsModule)
      },
      {
        path: 'estimates',
        loadChildren: () => import('./views/pages/estimates/estimates.module').then(m => m.EstimatesModule)
      },
      {
        path: 'customers',
        loadChildren: () => import('./views/pages/customers/customers.module').then(m => m.CustomersModule)
      },
      {
        path: 'dealers',
        loadChildren: () => import('./views/pages/dealers/dealers.module').then(m => m.DealersModule)
      },
      {
        path: 'collections',
        loadChildren: () => import('./views/pages/collections/collections.module').then(m => m.CollectionsModule)
      },
      {
        path: 'schedulers',
        loadChildren: () => import('./views/pages/scheduler/scheduler.module').then(m => m.SchedulerModule)
      },
      {
        path: 'tutorials',
        loadChildren: () => import('./views/pages/tutorials/tutorials.module').then(m => m.TutorialsModule)
      },
      {
        path: 'apps',
        loadChildren: () => import('./views/pages/apps/apps.module').then(m => m.AppsModule)
      },
      {
        path: 'ui-components',
        loadChildren: () => import('./views/pages/ui-components/ui-components.module').then(m => m.UiComponentsModule)
      },
      {
        path: 'advanced-ui',
        loadChildren: () => import('./views/pages/advanced-ui/advanced-ui.module').then(m => m.AdvancedUiModule)
      },
      {
        path: 'form-elements',
        loadChildren: () => import('./views/pages/form-elements/form-elements.module').then(m => m.FormElementsModule)
      },
      {
        path: 'advanced-form-elements',
        loadChildren: () => import('./views/pages/advanced-form-elements/advanced-form-elements.module').then(m => m.AdvancedFormElementsModule)
      },
      {
        path: 'charts-graphs',
        loadChildren: () => import('./views/pages/charts-graphs/charts-graphs.module').then(m => m.ChartsGraphsModule)
      },
      {
        path: 'tables',
        loadChildren: () => import('./views/pages/tables/tables.module').then(m => m.TablesModule)
      },
      {
        path: 'icons',
        loadChildren: () => import('./views/pages/icons/icons.module').then(m => m.IconsModule)
      },
      {
        path: 'general',
        loadChildren: () => import('./views/pages/general/general.module').then(m => m.GeneralModule)
      },
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      // { path: '**', redirectTo: 'dashboard', pathMatch: 'full' }
    ]
  },
  {
    path: 'error',
    component: ErrorPageComponent,
    data: {
      'type': 404,
      'title': 'Page Not Found',
      'desc': 'Oopps!! The page you were looking for doesn\'t exist.'
    }
  },
  {
    path: 'error/:type',
    component: ErrorPageComponent
  },
  { path: '**', redirectTo: 'error', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
