import { Component, OnInit, Inject, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

//service
import { CompanyService } from 'src/app/services/company.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  datoUsuario;
  logoProfile:string;
  currentCompany:string='Legacy';
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private router: Router,
    private companyService: CompanyService,
    private modalService: NgbModal,
  ) {
    this.datoUsuario = "";
   }

  ngOnInit(): void {

    this.datoUsuario = JSON.parse(localStorage.getItem('usuario'));
  }

  /**
   * Sidebar toggle on hamburger button click
   */
  toggleSidebar(e) {
    e.preventDefault();
    this.document.body.classList.toggle('sidebar-open');
  }

  /**
   * Logout
   */
  onLogout(e) {
    e.preventDefault();
    localStorage.removeItem('isLoggedin');
    localStorage.removeItem('nameCompany');

    if (!localStorage.getItem('isLoggedin')) {
      this.router.navigate(['/login']);
    }
  }

  textSelect(){
    console.log(this.currentCompany);
    this.companyService.setCurrentCompany(this.currentCompany);
  }

  openModal(content){
    this.modalService.open(content, {size: 'lg'}).result.then((result) => {
    }).catch((res) => {});
  }

}
